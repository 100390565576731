import React, { ReactNode } from "react";
import { ModalHeader } from "./ModalHeader";
import { twMerge } from "tailwind-merge";
// This is to give backward compatibility to existing modals. It is not encouraged to use this, please use BaseModal instead

export interface ModalProps {
    title?: string | ReactNode;
    description?: string | ReactNode;
    children: ReactNode;
    showClose?: boolean;
    onClose?: () => void;
    className?: string;
}
export const Modal = ({
    title,
    description,
    children,
    showClose = true,
    onClose,
    className
}: ModalProps) => {
    return (
        <div
            className={twMerge(
                "fixed flex flex-col w-full h-full z-50 left-0 top-0 overflow-auto bg-charcoal/70 modalFS:p-8",
                className
            )}
            onClick={onClose}
        >
            <div
                className="relative w-full max-w-[585px] modalFS:rounded-lg shadow dark:bg-gray-700 px-6 py-8 lg:px-[31px] flex-grow modalFS:flex-grow-0 bg-white m-auto flex flex-col"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex-grow-0">
                    {(title || (showClose && onClose)) && (
                        <ModalHeader
                            title={title}
                            onDone={showClose ? onClose : undefined}
                        />
                    )}
                </div>
                <div className="w-full flex-grow space-y-4">
                    {description && (
                        <p className="text-medium text-secondary font-normal text-left">
                            {description}
                        </p>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
};
