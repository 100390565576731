import { useCallback, useEffect, useRef, useState } from "react";
import { signIn, signOut, useSession } from "next-auth/react";

import { UserProfile } from "api/user/profile";
import { User } from "pages/api/user";
import { URI_HOME, URI_LOGIN, URL_BASE } from "constants/urls";
import { getEncodedURlErrorMessage } from "utils/form/form-utils";
import { getStaticGoogleLink } from "utils/google-links";
import PortalAnalytics from "analytics/PortalAnalytics";

import { getData } from "utils/http-requests";
import { AccessibleFeatures } from "../types";
import { AuthProviders } from "../constants";
import { checkFeatureEnabled } from "../utils/feature-flags";

export const useAuth = () => {
    const [user, setUser] = useState({
        isLoggedIn: false,
        login: "",
        roles: []
    } as User);
    const { data: session, status } = useSession();
    // @ts-ignore
    const profileId = session?.userProfile?.id.toString() as string;
    const email = session?.userProfile?.email;
    const firstName = session?.userProfile?.firstName;
    const lastName = session?.userProfile?.lastName;
    const refPortalAnalytics = useRef(0);
    const handlePortalIdentify = useCallback(() => {
        if (profileId && refPortalAnalytics.current === 0) {
            PortalAnalytics.identify(profileId, {
                email,
                first_name: firstName,
                last_name: lastName
            });
            refPortalAnalytics.current++;
        }
    }, [profileId, email, firstName, lastName, refPortalAnalytics]);

    useEffect(() => {
        if (session?.user && session?.userProfile) {
            const sessionUser = {
                // @ts-ignore
                roles: session?.userProfile?.roles,
                login: session?.user.name || "",
                isLoggedIn: true
            };
            handlePortalIdentify();
            setUser(sessionUser);
        }
        if (session?.userProfileError) {
            const error = session?.userProfileError as string;
            signOut({
                callbackUrl: getEncodedURlErrorMessage(URI_LOGIN, error)
            });
        }
    }, [session?.user, session?.userProfileError, session?.userProfile]);

    const handleChangeUserProfile = async (response?: UserProfile) => {
        if (response) {
            // @ts-ignore
            session.userProfile = response;

            return;
        }
        if (session?.accessToken) {
            try {
                const userProfileData = await getData(
                    `${URL_BASE}profile/`,
                    // @ts-ignore
                    session?.accessToken
                );
                // @ts-ignore
                session.userProfile = new UserProfile(userProfileData);
            } catch (e) {
                console.error(e);
            }
        }
    };

    const handleFirstLoginUser = async (
        response: string,
        callbackDestination = URI_HOME
    ) => {
        try {
            await signIn(AuthProviders.FIRST_TIME_LOGIN, {
                accessToken: response,
                redirect: true,
                callbackUrl: callbackDestination
            });
        } catch (e) {
            console.error(e);
        }
    };

    const getUserGoogleLink = (urls: string[] | string) => {
        const userProfile = session?.userProfile;

        if (typeof urls === "string") {
            const urlPatterns = ["docs.google.com", "drive.google.com"];
            let parsedUrl: any = urls;

            try {
                const searchParams = new URL(parsedUrl);

                const queryDivider = [...searchParams.searchParams.keys()]
                    .length
                    ? "&"
                    : "?";

                if (urlPatterns.some(pattern => urls.includes(pattern))) {
                    parsedUrl = `${parsedUrl}${queryDivider}authuser=${userProfile.email}`;
                }
            } catch (e) {
                // @ts-ignore
                console.log(`${e.name}: ${e.message}:`, parsedUrl);
                console.log("Make sure the link includes https://");
                return "#";
            }

            return parsedUrl;
        }

        try {
            return getStaticGoogleLink(urls)
                ? `${getStaticGoogleLink(urls)}${userProfile.email}`
                : "";
        } catch (e) {
            console.error(e);
            return "#";
        }
    };

    const isFeatureEnabled = (
        featureKey: string,
        featureValue: string = ""
    ): boolean => {
        const accessibleFeatures = session?.accessibleFeatures;
        return checkFeatureEnabled(
            accessibleFeatures ?? {},
            featureKey,
            featureValue
        );
    };

    return {
        user,
        userProfile: session?.userProfile as UserProfile,
        impersonatedUserProfile:
            (session?.impersonatedUserProfile as UserProfile) || null,
        handleChangeUserProfile,
        handleFirstLoginUser,
        getUserGoogleLink,
        token: session?.accessToken as string,
        accessibleFeatures: session?.accessibleFeatures as AccessibleFeatures,
        isFeatureEnabled,
        status
    };
};
