import { DynamicForm } from "components/form/DynamicForm";
import ADD_CLIENT_FORM_SCHEMA from "components/form/schemas/AddClientSchema.json";

interface Props {
    onDone?: () => void;
    defaultValues?: { [key: string]: number | string };
}

export const AddClientForm = ({ onDone, defaultValues }: Props) => {
    return (
        <DynamicForm
            schema={ADD_CLIENT_FORM_SCHEMA}
            onDone={onDone}
            defaultValues={defaultValues}
        />
    );
};
