import Image from "next/image";
import { URI_HOME } from "../../constants/urls";
import FooterMinimal from "../../components/layout/FooterMinimal";
import React, { PropsWithChildren } from "react";
import { Button } from "components/common/v2/button/Button";

const ErrorPage = ({
    children,
    showBackButton = true
}: PropsWithChildren<{ showBackButton?: boolean }>) => {
    return (
        <>
            <div className="min-h-full w-full flex flex-col py-6 lg:py-12 sm:px-6 lg:px-8">
                <main className="m-auto w-full">
                    <div className="flex-shrink-0 flex justify-center mb-8">
                        <a href={URI_HOME} className="inline-flex">
                            <span className="sr-only">Home</span>
                            <Image
                                src="/img/logo_fora_min.svg"
                                alt="Fora Travel"
                                width={128}
                                height={40}
                                className="mx-auto h-10 w-auto"
                            />
                        </a>
                    </div>
                    <div className="relative py-16 overflow-hidden">
                        <div className="text-center relative px-8">
                            <p className="text-staticMobileTitle font-semibold text-black-900">
                                {children
                                    ? children
                                    : "Sorry, we couldn’t find the page you’re looking for."}
                            </p>

                            {showBackButton && (
                                <div className="relative mt-5 max-w-md mx-auto flex justify-center md:mt-8">
                                    <a href={URI_HOME}>
                                        <Button>Go back home</Button>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
                <div className="mt-8">
                    <FooterMinimal />
                </div>
            </div>
        </>
    );
};

export default ErrorPage;
