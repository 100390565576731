import React, { PropsWithChildren } from "react";
import { SWRConfig } from "swr";
// @ts-ignore
import camelize from "camelize";

import { useAuth } from "hooks/use-auth";
import { getFullData, throwIfNotOk } from "utils/http-requests";

const getFetcher = async (url: string, token: string, camelizeData = true) => {
    const fetchResult = getFullData(url, token).then(throwIfNotOk);
    const { data } = await fetchResult;
    return camelizeData ? camelize(data) : data;
};

type SWRContextProps = PropsWithChildren<{}> & { camelizeData?: boolean };

export const SWRContext = ({
    children,
    camelizeData = true
}: SWRContextProps) => {
    const { token } = useAuth();
    const fetcher = async (url: string) => {
        return getFetcher(url, token, camelizeData);
    };
    return (
        <SWRConfig
            value={{
                fetcher
            }}
        >
            {children}
        </SWRConfig>
    );
};
