import React from "react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useAuth } from "hooks/use-auth";
import Loader from "components/common/Loader";
import fireTheConfetti from "utils/fire-the-confetti";
import { getNumberOfBookingsForUser } from "api/bookings/bookings";
import { useMarkLessonCompleted } from "hooks/training/v2/useMarkLessonCompleted";

export const CertificationQuizSuccess = ({
    closeModal
}: {
    closeModal: () => void;
}) => {
    const { token } = useAuth();

    const { markLessonAsComplete } = useMarkLessonCompleted({
        track: { slug: "certified" },
        module: { slug: "certified-quiz" }
    });

    useEffect(() => {
        //NOTE: Should we hardcode the data or load it
        markLessonAsComplete({
            id: 239,
            slug: "take-the-certified-quiz",
            is_completed: false,
            internal_name: "",
            name: "",
            lesson_type: "",
            complete_time: 10,
            sequence: 1
        });
        fireTheConfetti();
    }, []);

    // In the case where the user passed the certification quiz (success state) we need to get num bookings to know if they've completed their first
    // booking as the message will differ depending on that.
    // We don't need this data in the failure state, so the ordering of the ternary logic in the return statement is important to ensure we only show
    // the loader when we need to (i.e. if the user succeeded but we don't have the booking data yet)
    const [numBookings, setNumBookings] = useState<number | undefined>(
        undefined
    );
    const [bookingDataLoading, setBookingDataLoading] =
        useState<boolean>(false);
    useEffect(() => {
        if (!token) return;
        setBookingDataLoading(true);
        getNumberOfBookingsForUser(token).then(res => {
            setNumBookings(res);
            setBookingDataLoading(false);
        });
    }, [token]);
    const hasCompletedFirstBooking =
        numBookings !== undefined && numBookings > 0;

    if (bookingDataLoading) {
        return <Loader />;
    }
    return (
        <>
            <div className="mb-6">
                <div className="text-medium font-bold text-secondary flex flex-col gap-4">
                    {hasCompletedFirstBooking ? (
                        <p>
                            Look out for an email from us early next month with
                            more information on your new certification and
                            badge!
                        </p>
                    ) : (
                        <>
                            <p>
                                To earn your certification, you also need to
                                make your first booking through the Portal.
                            </p>
                            <p>
                                Once you complete your first booking, look out
                                for an email from us early next month with more
                                information on your new certification and badge!
                            </p>
                        </>
                    )}
                    <p>
                        We issue certification at the beginning of each month.
                    </p>
                </div>
            </div>
            <div className={"flex items-center justify-end gap-6"}>
                {hasCompletedFirstBooking ? (
                    <button
                        className="btn-primary py-3 px-6 w-full md:w-auto min-w-[86px]"
                        onClick={() => closeModal()}
                    >
                        Got it
                    </button>
                ) : (
                    <Link
                        href="/partners"
                        className="btn-primary py-3 px-6 w-full md:w-auto min-w-[86px]"
                    >
                        Let’s get booking
                    </Link>
                )}
            </div>
        </>
    );
};
