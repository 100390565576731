import React from "react";
import { Icon as PhosphorIconT } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import styles from "./Button.module.css";

type SizeT = "small" | "medium";
type VariantT = "primary" | "secondary" | "text";

export interface ButtonProps extends React.ComponentProps<"button"> {
    PhosphorIcon?: PhosphorIconT;
    size?: SizeT;
    variant?: VariantT;
}

const getSizeStyle = (size: SizeT, isIcon = false) => {
    if (size === "small")
        return twMerge(styles["btn-size-small"], isIcon ? "px-[6px]" : "");
    return twMerge(styles["btn-size-medium"], isIcon ? "px-[10px]" : "");
};

const getVariantStyle = (variant: VariantT) => {
    if (variant === "text") return styles["btn-variant-text"];
    if (variant === "secondary") return styles["btn-variant-secondary"];
    return styles["btn-variant-primary"];
};

/* If you want to use an icon on the right use the children to pass text and icon and ignore the PhosphorIcon parameter */
export const Button = ({
    children,
    variant = "primary",
    type = "button",
    size = "medium",
    PhosphorIcon,
    color,
    ...props
}: ButtonProps) => {
    const hasLabel = !!children;
    const sizeStyle = getSizeStyle(size, !hasLabel);
    const variantStyle = getVariantStyle(variant);
    const { className, ...otherProps } = props;

    return (
        <button
            type="button"
            className={twMerge(
                "btn",
                sizeStyle,
                variantStyle,
                color && styles?.[color],
                className
            )}
            {...otherProps}
        >
            {PhosphorIcon && (
                <PhosphorIcon
                    size={size === "small" ? 16 : 18}
                    className={hasLabel ? "mr-2" : ""}
                />
            )}{" "}
            {children}
        </button>
    );
};
