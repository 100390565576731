import { useEffect, useState } from "react";
import PortalAnalytics from "PortalAnalytics";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
const MODAL_QUERY_PARAM_NAME = "modal";

const VALID_MODALS = [
    "report-issue",
    "request-supplier-login",
    "certification-quiz",
    "add-client",
    "test"
];

export const isValidModal = (id?: string | null): boolean => {
    if (!id) return false;
    return VALID_MODALS.includes(id);
};

interface UseModalManagerReturn {
    modalId?: string | undefined;
    closeModal: () => void;
    openModal: (modalId: string) => void;
}

export const useModalManager = (): UseModalManagerReturn => {
    const [lastModal, setLastModal] = useState();
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const router = useRouter();
    const modalId = searchParams.get(MODAL_QUERY_PARAM_NAME);
    const isValid = isValidModal(modalId);

    const updateURL = (queryParams: URLSearchParams) => {
        const queryString = queryParams.toString();
        // Notice that {scroll: false} will fail until we upgrade NextJS
        router.replace(`${pathname}${queryString ? `?${queryString}` : ""}`, {
            scroll: false
        });
    };
    const closeModal = () => {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        PortalAnalytics.track("modal__track_close", {
            modalId
        });
        newSearchParams.delete(MODAL_QUERY_PARAM_NAME);
        updateURL(newSearchParams);
    };
    const openModal = (modalId: string) => {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.set(MODAL_QUERY_PARAM_NAME, modalId);
        updateURL(newSearchParams);
        PortalAnalytics.track("modal__track_open", {
            modalId
        });
    };
    useEffect(() => {
        if (!isValid && modalId) {
            PortalAnalytics.track("modal__track_invalid", {
                modalId
            });
        }
    }, [isValid, modalId]);

    return {
        modalId: isValid ? modalId || undefined : undefined,
        closeModal,
        openModal
    };
};
